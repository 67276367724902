import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Container from './container'
import * as styles from './article-preview.module.css'

const ArticlePreview = ({ posts, externals }) => {
  if (!posts) return null
  if (!Array.isArray(posts)) return null

  return (
    <Container>
      <ul className={styles.articleList}>
        {posts.map((post) => {
          return (
            <li key={post.slug}>
              <Link to={`/work/${post.slug}`} className={styles.link}>
                <GatsbyImage alt="" image={post.previewImage.gatsbyImageData} />
                <h2 className={styles.title}>{post.title}</h2>
              </Link>
              <div
                dangerouslySetInnerHTML={{
                  __html: post.description.childMarkdownRemark.html,
                }}
              />
            </li>
          )
        })}
        {externals.map((external) => {
          return (
            <li key={external.slug}>
              <a
                href={external.url}
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                <GatsbyImage
                  alt=""
                  image={external.previewImage.gatsbyImageData}
                />
                <h2 className={styles.title}>{external.title}</h2>
              </a>
              <div
                dangerouslySetInnerHTML={{
                  __html: external.description.childMarkdownRemark.html,
                }}
              />
            </li>
          )
        })}
      </ul>
    </Container>
  )
}

export default ArticlePreview
